import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'

import { Swipe, SwipeItem } from 'vant'
import { Circle } from 'vant'

Vue.use(Circle)
Vue.prototype.imgUrl = 'https://begood.gz.bcebos.com/downImg'
Vue.prototype.$axios = Axios
Vue.config.productionTip = false

Vue.use(Swipe)
Vue.use(SwipeItem)

// vue原型挂载 - 是否PC端
if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  Vue.prototype.$pc = false
} else {
  Vue.prototype.$pc = true
  let winWidth =
    document.documentElement.offsetWidth || document.body.offsetWidth
  winWidth = winWidth < 1366 ? 1366 : winWidth
  let oHtml = document.getElementsByTagName('html')[0]
  oHtml.style.fontSize = (100 * winWidth) / 1920 + 'px'
  window.addEventListener('resize', function() {
    let winWidth =
      document.documentElement.offsetWidth || document.body.offsetWidth
    winWidth = winWidth < 1366 ? 1366 : winWidth
    let oHtml = document.getElementsByTagName('html')[0]
    oHtml.style.fontSize = (100 * winWidth) / 1920 + 'px'
  })
}

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
