<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },

  created() {
    if (
      window.location.href.split('?')[1] == undefined ||
      window.location.href.split('?')[1] == '' ||
      window.location.href.split('?')[1] == null
    ) {
    } else {
      if (window.location.hash.indexOf('a_code') != -1) {
        localStorage.setItem('code', window.location.href.split('?')[1])
      }
    }

    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      if (localStorage.getItem('code')) {
        this.$router.push('./newdowmMobile?' + localStorage.getItem('code'))
      } else {
        this.$router.push('./newdowmMobile')
      }
    } else {
      if (localStorage.getItem('code')) {
        this.$router.push('./newdowmPc?' + localStorage.getItem('code'))
      } else {
        this.$router.push('./newdowmPc')
      }
    }
    window.onload = function() {
      document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function(event) {
        event.preventDefault()
      })
    }
  }
}
</script>

<style>
@import 'assets/css/base.css';
</style>
