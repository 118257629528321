import Vue from 'vue'
import VueRouter from 'vue-router'

const NewDownPc = () => import('../views/newDown-pc.vue')
const NewdowmMobile = () => import('../views/newDown-mobile.vue')
const Setting = () => import('../views/setting.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/newdowmPc',
    component: NewDownPc
  },
  {
    path: '/newdowmMobile',
    component: NewdowmMobile
  },
  {
    path: '/setting',
    component: Setting
  },
  {
    path: '/various',
    component: () => import('../views/various.vue')
  }
]


const router = new VueRouter({
  routes
  // mode: 'hash',
})

export default router
